import React, { useEffect, useState, Component } from 'react'
import API from '../../axios/api'
import { Grid, Typography, Paper, Hidden } from '@material-ui/core'
import baseURL from '../../config/url'

import "./noticias.css"
import {
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,} from 'react-share';
  import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from 'react-share';
import Categorias from './Categorias'
class NoticiaView extends Component{
    constructor(props){
        super(props)
        this.state={
            noticia:{},
            
        }
    }
    componentDidMount(){
        this.cargar(this.props.match.params.id)
    }
    componentDidUpdate(){
        /// console.log(this.state.cat,this.props.match.params.cat)
         if(this.state.noticia.id_noticia !== this.props.match.params.id){
             this.cargar(this.props.match.params.id)
         }     
          return false;
      }
    cargar(ide){
        API.get('api/noticia/'+ide)
        .then(response => {
          ///mandar al estado
  
          //this.props.cargarNoticia(response.data)
         // console.log(response.data)

          this.setState({noticia:response.data})
          
        })
        .catch(error => {
          console.log(error)
        })
        .finally(function () {
  
        })
    }
    urlShare= baseURL+"articulo/index/"+this.props.match.params.id;
    

    render(){
      
            return(
                <>
                 {this.state.noticia.img &&
                
                <Grid container>
                   <Hidden mdUp ><Categorias categoria={this.props.match.params.cat}/></Hidden>
                    <Grid item xs={12} md={9} className="noti-view">
                        <Paper className="noti-view noti-comp">
                             
                        <Typography variant="h3" component="h2" style={{color:this.props.color, fontWeight:500,fontStyle:"italic"}} align="center">{this.state.noticia.titulo}</Typography>
                        <div className="noti-img">
                        <img src={baseURL+"assets/img/noticias/"+this.state.noticia.img} />
                 <Typography variant="subtitle2" component="h4">{this.state.noticia.fecha_noticia}</Typography>
                        </div>
                        <div className="noti-body">
                        <div dangerouslySetInnerHTML={{__html:this.state.noticia.descripcion}}/>
                        </div>
                        <div className="share-container">
                                <div className="share-item">
                                <FacebookShareButton
                                     url={this.urlShare}
                                     quote={this.state.noticia.titulo}
                                    className="share-button">
                                    <FacebookIcon
                                    size={32}
                                    borderRadius={10} />
                                 </FacebookShareButton>
                              
                                </div>
                                <div className="share-item">
                                <TwitterShareButton
                                    url={this.urlShare}
                                    quote={this.state.noticia.titulo}
                                    className="share-button">
                                    <TwitterIcon
                                    size={32}
                                    borderRadius={10} />
                                 </TwitterShareButton>
                               
                                </div>
                                <div className="share-item">
                                <WhatsappShareButton
                                    url={this.urlShare}
                                    quote={this.state.noticia.titulo}
                                    separator=":: "
                                    className="share-button">
                                    <WhatsappIcon 
                                    size={32}
                                    borderRadius={10} />
                                 </WhatsappShareButton>
                               
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>      
                    <Hidden smDown>
                    <Categorias categoria={this.props.match.params.cat}/>  
                    </Hidden>                  
                                            
                        
                    </Grid>
                </Grid>}
                
                </>
            )
        
        
    }
}
export default NoticiaView