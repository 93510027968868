import React from 'react'
import { GridList, GridListTile, GridListTileBar, IconButton, Hidden, Typography, Grid, Divider, Button } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';
import './noticias.css'
import baseURL from '../../config/url';
import { Link } from 'react-router-dom';




const ComponenteNoticia = (props)=>{
   
    return(
       <>
       
                       
                <Grid container spacing={2}  >
                    {
                       props.noticia.map(row=>(
                        
                     <Grid key={row.id} item xl={3} lg={4} sm={6} xs={12} className="notia" >
                     <Grid className="mediaWrapper" >
                         <Grid component="a" href={"/noticia/"+row.categoria+"/"+row.id+"/"+row.id_categoria}>
                         <Grid className="mediaImages">
                             <img src={baseURL+"assets/img/noticias/"+row.img} alt="" />                            
                         </Grid>
                         <Grid className="mediaContent">
                             <h4>{row.title}</h4>
                                <span style={{fontSize:'.8rem'}}>{row.fecha}</span>
                             <span className="text">{row.titular}</span>
                             <Typography  variant="body2" align="right" style={{color:props.colora}}>Leer mas...</Typography>
                         </Grid>
                         </Grid>
                     </Grid>
                 </Grid>
                       ))
                    }
                </Grid>
            
           
           
        </>
    )
}

export default ComponenteNoticia