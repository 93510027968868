import React from 'react'
import {
  FacebookIcon
} from 'react-share';
import { IconButton, Tooltip } from '@material-ui/core';

  const Head =(props)=>{
  
 
  return (
  
      
      <div className="media-float">
          <div className="btn">
           
       
           
          </div>
          </div>

  
  )

}


export default Head