import React, { Component } from 'react'
import API from '../../axios/api'
import { connect } from 'react-redux'
import baseURL from '../../config/url'
import Titulo from '../Titulos'
import { Typography, Grid, Paper, Container } from '@material-ui/core'
import './somos.css'
class Somos extends Component{
    
    constructor(props){
        super(props)
        this.state={
            somos:{}
        }
    }

    componentDidMount(){
        this.cargar()
    }
    cargar=()=>{
        
      API.get('api/nosotros/')
      .then(response => {
        ///mandar al estado
        this.setState({somos:response.data})
      })
      .catch(error => {
      //  console.log(error)
      })
      .finally(function () {

      })
    }

    render(){
       /// console.log(this.state.somos)
        return(
            <>

            {
                
               
                this.state.somos.nosotros && 
                <div className="somos" style={{ padding:"1rem", backgroundImage:"linear-gradient(rgb(255,255,255,0.86), rgb(255,255,255,0.86)), url("+baseURL+"assets/img/web/"+this.props.config.configuracion.fondo+")"}}>
                <Container maxWidth="md" fixed>
                <Grid item xs={12}>
                 <Titulo color={this.props.config.configuracion.color_a} texto="Misión" />
                     <Typography>{this.state.somos.nosotros.mision}</Typography>
                <Titulo color={this.props.config.configuracion.color_a} texto="Visión" />
                    <Typography>{this.state.somos.nosotros.vision}</Typography>
                 </Grid>



             </Container>
            </div>
            
        }
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      config: state.main.empresa,
     
    }
  }
export default connect(mapStateToProps,null)(Somos)