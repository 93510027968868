import React, { Component } from 'react'
import { connect } from 'react-redux'
import API from '../../axios/api'
import OpinionCard from './OpinionCard'
import Titulo from '../Titulos'
import { Grid } from '@material-ui/core'
import baseURL from '../../config/url'


class OpinionPage extends Component{
    constructor(props){
        super(props)
        this.state={
            opinion:[]
        }
    }

    componentDidMount(){
        this.cargar()
    }

    cargar(){
        
      API.get('api/opinion/')
      .then(response => {
        ///mandar al estado
        
        this.setState({opinion:response.data})
      })
      .catch(error => {
      //  console.log(error)
      })
      .finally(function () {

      })
    }

    render(){

        //console.log(this.state.opinion)
        return(
            <>
           {
               this.state.opinion.length > 0 &&
               <Grid container className="opinion" style={{backgroundImage:"linear-gradient(rgb(255,255,255,0.86), rgb(255,255,255,0.86)), url("+baseURL+"assets/img/web/"+this.props.config.configuracion.fondo+")"}}>
                    <Grid item xs={12}>
                        <Titulo texto="OPINIÓN" color={this.props.config.configuracion.color_a}/>
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                    <OpinionCard config={this.props.config.configuracion} opinion={this.state.opinion}/>
                    </Grid>
                </Grid>
              
           }
            </>
        )
    }

}
const mapStateToProps = (state) => {
    return {
      config: state.main.empresa,
     
    }
  }
export default connect(mapStateToProps,null)(OpinionPage)