import React, { Component } from 'react'
import { BrowserRouter,Switch, Route } from 'react-router-dom'
import Inicio from './pages/Inicio'
import NoticiaView from './components/Noticias/NoticiaView'
import Simple from './layuts/Simple'
import NoticiaCategoria from './components/Noticias/NoticiaCategoria'
import Somos from './components/somos/somos'
import OpinionPage from './components/Opinion/OpinionPage'
import OpinionView from './components/Opinion/OpinionView'



const Ruta = props =>{
    const { layout: Layout, component: Component, exact=false, path } = props;
    return (
        <Route
          exact={exact}
          path={path}    
          render={matchProps => (
              <Layout>
              <Component {...matchProps} />
            </Layout>
            
          )}
        />
        
      
      
      
      );
}

const Routes = ()=>{
    return(
        <>
        <BrowserRouter>
        <Switch>
            <Ruta
            exact
            path = '/'
            component ={Inicio}
            layout={Simple}
            />
            <Ruta
            exact
            path = '/noticia/:categoria/:id/:cat'
            component ={NoticiaView}
            layout={Simple}
            />
            <Ruta
            exact
            path = '/noticias/:categoria/:cat'
            component ={NoticiaCategoria}
            layout={Simple}
            />
            <Ruta
            exact
            path = '/somos'
            component ={Somos}
            layout={Simple}
            />
            <Ruta
            exact
            path = '/opinion'
            component ={OpinionPage}
            layout={Simple}
            />
             <Ruta
            exact
            path = '/opinion/:id'
            component ={OpinionView}
            layout={Simple}
            />
            <Route
                path='*'
                component={()=>"404 NOT FOUND"}
            />
        </Switch>
        </BrowserRouter>
        </>
    )
}

export default Routes
