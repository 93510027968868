import React, { Suspense, lazy } from 'react';
import Load from '../components/load/Load';
import Opinion from '../components/Opinion/Opinion';
//import 'react-circular-progressbar/dist/styles.css';
///import { CircularProgressbar } from 'react-circular-progressbar';
const Noticias= lazy(()=>import('../components/Noticias/Noticias'));

const Inicio = ()=>{
    return(
        <>
       
        <Suspense fallback={<Load/>}>
            <Noticias/>
        </Suspense>
        <Suspense fallback={<Load/>}>
            <Opinion/>
        </Suspense>
        </>        
    )
}

export default Inicio