import React, { Component } from 'react'
import { Container, Paper, Typography } from '@material-ui/core'
import Titulo from '../Titulos'
import baseURL from '../../config/url'
import API from '../../axios/api'
import './opinion.css'
import {
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,} from 'react-share';
  import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from 'react-share';
class OpinionView extends Component{
    constructor(props){
        super(props)
        this.state={
            opinion:{}
        }
    }

    componentDidMount(){
        this.cargar()
    }
    cargar(){
        API.get('api/opi/'+this.props.match.params.id)
        .then(response => {
          ///mandar al estado
          
          this.setState({opinion:response.data})
        })
        .catch(error => {
        //  console.log(error)
        })
        .finally(function () {
  
        })
    }
    

    render(){
      const urlShare= baseURL+"articulo/op/"+this.props.match.params.id;
      const titleShare = this.state.opinion.titulo;
        return(
            <Container>
                <Paper style={{padding:"1.5rem"}}>
                    <Titulo texto={this.state.opinion.titulo} align="center"/>
                    
                    <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                    <img className="img-opinion" src={baseURL+"assets/img/otros/"+this.state.opinion.img} />
                    </div>
                    <Typography variant="subtitle2" align="center">{this.state.opinion.descripcion}</Typography>
                    <div dangerouslySetInnerHTML={{__html:this.state.opinion.descripcionl}}/>
                    <div className="share-container">
                                <div className="share-item">
                                <FacebookShareButton
                                     url={urlShare}
                                     quote={titleShare}
                                    className="share-button">
                                    <FacebookIcon
                                    size={32}
                                    borderRadius={10} />
                                 </FacebookShareButton>
                              
                                </div>
                                <div className="share-item">
                                <TwitterShareButton
                                    url={urlShare}
                                    quote={titleShare}
                                    className="share-button">
                                    <TwitterIcon
                                    size={32}
                                    borderRadius={10} />
                                 </TwitterShareButton>
                               
                                </div>
                                <div className="share-item">
                                <WhatsappShareButton
                                    url={urlShare}
                                    quote={titleShare}
                                    separator=":: "
                                    className="share-button">
                                    <WhatsappIcon 
                                    size={32}
                                    borderRadius={10} />
                                 </WhatsappShareButton>
                               
                                </div>
                            </div>
                </Paper>
            </Container>
        )
    }


}

export default OpinionView