
import { createStore, combineReducers } from 'redux';
import main from './reducers/main'
import noticia from './reducers/noticias'

const store = createStore(combineReducers({ 
    main,
    noticia
   
}));




export default store;