const noticia={
    noticia:[],
    noticiax:[],
    categorias:[],
    active:'',
    nota:[],
    masv:[]
}


function reducer (state = noticia, action){
        switch(action.type){
            case 'ACTUALIZAR_NOTICIA':{
               
                return{
                    ...state,
                    noticia: action.noti
                }
            }
            case 'ACTUALIZAR_NOTICIAX':{
                
                 return{
                     ...state,
                     noticiax: action.notix
                 }
             }
            case 'NOTICIAS_CATEGORIA':{
                return{
                    ...state,
                    noticiat: action.noti                    
                }
            }
            case'LISTA_CATEGORIA':{
                return{
                    ...state,
                    categorias:action.cate
                }
            }
            case 'ACTIVE_NOTICIA':{
                return{
                    ...state,
                    active:action.active
                } 
            }
            case 'CARGAR_NOTICIA':{
                return{
                    ...state,
                    nota:action.noticia
                }
            }
            case 'MAS_VISTOS':{
                return{
                    ...state,
                    masv:action.masv
                }
            }
            default:
                return state;
        }
}
export default reducer;