import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import Titulo from '../Titulos'
import { connect } from 'react-redux'
import OpinionCard from './OpinionCard'
import './opinion.css'
import baseURL from '../../config/url'
class Opinion extends Component{
    render(){
        return(
            <>
                <Grid container className="opinion" style={{backgroundImage:"linear-gradient(rgb(255,255,255,0.86), rgb(255,255,255,0.86)), url("+baseURL+"assets/img/web/"+this.props.config.configuracion.fondo+")"}}>
                    <Grid item xs={12}>
                        <Titulo texto="OPINIÓN" color={this.props.config.configuracion.color_a}/>
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                            <OpinionCard config={this.props.config.configuracion} opinion={this.props.config.opinion}/>
                    </Grid>
                </Grid>                        
            </>
        )
    }

}
const mapStateToProps = (state) => {
    return {
      config: state.main.empresa,
     
    }
  }
export default connect(mapStateToProps,null)(Opinion)