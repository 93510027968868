import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {  Button, Grid, Hidden } from '@material-ui/core';
import './layaut.css'
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import NotesIcon from '@material-ui/icons/Notes';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));


 

const NavBar=(props)=>{
    const classes = useStyles();
    const [value,setValue] = React.useState(0); 
    
    const handleChange = (event, newValue) => {
      //modificarMenu(newValue)
     
      setValue(newValue);
    };
    
            return(<>
                <Hidden mdUp >
                    <BottomNavigation value={value} onChange={handleChange} showLabels className={classes.root+ " fixedbutton"}>
                <BottomNavigationAction  label="Inicio" value="inicio"  icon={<HomeIcon />}  href={"/"}/> 
                <BottomNavigationAction  label="Noticias" value="noticias"  icon={<NotesIcon />}  href={"/noticias/"+props.config.categorias[0].nombre_categoria+"/"+props.config.categorias[0].id_categoria} />
                
                <BottomNavigationAction  label="Opinión" value="musica"  icon={<EmojiObjectsIcon />}  href={"/opinion"}/>
                <BottomNavigationAction  label="Somos" value="somos"  icon={<ViewHeadlineIcon />}  href={"/somos"}/>
                

                </BottomNavigation>
                </Hidden>
                <Hidden smDown>
                      <div className="header">
                    <header  >
                        <Grid container>
                        <Grid item xs={8} sm={12} md={5} xl={6}>

                        </Grid>
                        <Grid item xs={8} sm={12} md={7} xl={6}>
                        <div className="nav" style={{background:props.config.configuracion.color_a, borderRadius: "5em 0em 0em 5em" }}>
                            <Button
                            component={Link}
                            color="inherit"              
                            to={"/"}>inicio</Button>

                            <Popup
                            trigger={<Button color="inherit" component="a" className="menu-item"> Noticias </Button>}
                
                            on="hover"
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                            contentStyle={{ padding: "0px", border: "none" }}
                            arrow={true}
                            >
                            <div className="menu" >
                                    {
                                        props.config.categorias.map(row=>(
                                        <Button 
                                          className="menu-item" 
                                          style={{color:props.config.configuracion.color_a}}
                                          fullWidth  
                                          key={row.id_categoria} 
                                          component={Link} 
                                          to={"/noticias/"+row.nombre_categoria+"/"+row.id_categoria} 
                                        
                                          >
                                            {row.nombre_categoria}
                                        </Button>
                                        ))
                                    }
                            </div>
                            </Popup>

                            <Button
                            component={Link}
                            color="inherit"              
                            to={"/opinion"}
                            >Opinión</Button>
                            <Button
                            component={Link}
                            color="inherit"              
                            to={"/somos"}>quienes somos</Button>
                        </div>
                        </Grid>
                        </Grid>
                        
                        
                    </header>
                    </div>
                    </Hidden>
                    </>
                
            )
        
    

}
export default NavBar