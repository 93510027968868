import React, { Suspense, lazy } from 'react';
import { Grid } from '@material-ui/core';
const Main= lazy(()=>import('./layuts/Main'));

function App() {
  return (
    <div>
    <Suspense fallback={<div>Loading...</div>}>
    <Grid>

    </Grid>
    </Suspense>
      
    </div>
  );
}

export default App;
