import React from 'react'
import { connect } from 'react-redux'

import './noticias.css'
import { List, ListItem, ListItemText, ListSubheader, Divider, Hidden, ButtonGroup, Button, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { blueGrey } from '@material-ui/core/colors'

const Categorias = (props) =>{
  //  console.log(props)
    return(
        <>
        <Hidden smDown>
        <div className="categoria" style={{background:"linear-gradient("+props.config.configuracion.color_a+","+props.config.configuracion.color_a+")", filter: "alpha(opacity=50)",borderRadius: "2em 0em 0em 2em" }}>
           <List>
               <ListSubheader component="div" style={{color:"#fff",background:props.config.configuracion.color_a}}>
                    CATEGORIAS
               </ListSubheader>
               <Divider/>
               {
                   props.config.categorias.map(row=>(
                    <ListItem button key={row.id_categoria} 
                    selected={props.categoria === row.id_categoria}
                    component={Link} to={"/noticias/"+row.nombre_categoria+"/"+row.id_categoria} 
                    color={blueGrey[100]}
                    >
                         <ListItemText primary={row.nombre_categoria} />
                     </ListItem>
                   ))
               }
                  <Divider/>
                <ListSubheader component="div" style={{color:"#fff", background:props.config.configuracion.color_a}}>
                    LO MÁS VISTO
               </ListSubheader>
               {
                 props.config.masvistos.map(row=>
                  (<ListItem button key={row.id_noticia} style={{color:"#fff"}}            
                  component={Link} to={"/noticia/"+row.nombre_categoria+"/"+row.id_noticia+"/"+row.id_categoria} 
                 
                  >
                       <ListItemText    primary={row.title} />
                   </ListItem>)
                 )
               }
               <Divider/>
           </List>
        </div>
        </Hidden>
        <Hidden mdUp>
                <Grid container justify="center" style={{paddingTop:"1rem"}}>
                
                 
                 {
                    props.config.categorias.map((row,i)=>
                      (
                        <div key={i}>
                        {
                       props.categoria === row.id_categoria ?(
                         <Button color="primary">{row.nombre_categoria}</Button>
                       ):(
                         <Button 
                         component={Link} to={"/noticias/"+row.nombre_categoria+"/"+row.id_categoria}
                         >{row.nombre_categoria}</Button>
                       )
                      }
                        </div>
                      )
                    
                    )
                 }
             
                </Grid>
        </Hidden>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      config: state.main.empresa
    }
  }
 


export default connect(mapStateToProps, null)(Categorias)