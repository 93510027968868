const main = {
    empresa:[]

}

function reducer (state = main, action){
    switch(action.type){
        case 'CARGAR_MAIN':{
            return{
                ...state,
                empresa:action.main
            }
        }
        default:
            return state;
    }
}
export default reducer;