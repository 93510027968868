import React from 'react'
import { Card, CardHeader, CardMedia, CardContent, Grid, Typography } from '@material-ui/core'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import './opinion.css'
import baseURL from '../../config/url';



const dat=(date)=>{
     date =new Date(date)
    
    return date.getFullYear()+"-"+ (parseInt(date.getMonth())+1) +"-"+ date.getDate();
}

const OpinionCard =(props)=>{
  
    return(
        <>
        
        {props.opinion.map(row=>(
            <Grid item xs={12} md={4}  className="cardipm" key={row.id_nuestro} component="a" href={"/opinion/"+row.id_nuestro}>
                <div className="cardip">
           <div className="head">
           <img src={baseURL+"assets/img/otros/"+row.img}/>
           <Typography component="h3" variant="subtitle2" style={{color:props.config.color_a}}>{row.titulo}</Typography>
            <Typography component="p" variant="subtitle1" style={{color:"#000", fontSize:'.8rem'}} >{dat(row.fecha_registro)}</Typography>
           <Typography component="h3" variant="subtitle1" style={{color:"#000"}}>{row.descripcion}</Typography>
           </div>
           <div className="body">
           <FormatQuoteIcon style={{color:props.config.color_a}} className="abrir comilla"/>
                <Typography variant="body2"  className="contenido"> 
                    <span  dangerouslySetInnerHTML={{__html:row.descripcionl}}/>
                </Typography>
            <FormatQuoteIcon style={{color:props.config.color_a}} className="cerrar comilla" />
                
           </div>
            </div>
        </Grid>
        ))}
        </>
    )
}

export default OpinionCard