import React, { Component } from 'react'
import { connect } from 'react-redux'
import API from '../axios/api'
import NavBar from './NavBar';
import './layaut.css'
import baseURL from '../config/url';
import PropTypes from 'prop-types';
import Footer from './Footer';

import Head from '../components/Header'


class Simple extends Component{

    componentDidMount(){
      this.cargarDatos();
    }
    cargarDatos(){
      API.get('api/config/')
      .then(response => {
        ///mandar al estado

        ///this.props.cargarNoticia(response.data)
      
        this.props.cargarConfig(response.data)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(function () {

      })
    }

    
    render(){
      
     

      const { children } = this.props;
        return(
           <>
            {
              this.props.config.configuracion &&
              <div>
            <div className="homebg-simple" style={{backgroundImage:"linear-gradient("+this.props.config.configuracion.color_b+","+this.props.config.configuracion.color_b+"), url("+baseURL+"assets/img/web/"+this.props.config.configuracion.fondo+")"}}>
                           
                  <NavBar config={this.props.config}/>
              
              <div className="header-init">
                
              {/*<Typography variant="h2" component="h1" className="text-white">"El slogan de la empresa"</Typography>*/}
                
                <img  className="logo"src={baseURL+"assets/img/web/"+this.props.config.configuracion.logo} alt="logo"/>
              
              </div>             
              
            
            </div>
            <main>
             
              {children}
          
            </main>
              <Head redes={this.props.config.empresa}/>
                <Footer empresa={this.props.config.empresa} logo={baseURL+"assets/img/web/"+this.props.config.configuracion.logo}/>
            
            </div>
           }
            </>
        )
    }
}
Simple.propTypes = {
  children: PropTypes.node
};
const mapStateToProps = (state) => {
    return {
      config: state.main.empresa
    }
  }
  const mapDispatchToProps = (dispatch) => {
    return {
      cargarConfig(main) {
        dispatch({
          type: 'CARGAR_MAIN',
          main 
        })
      }
      
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(Simple)