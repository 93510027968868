import React, { Component } from "react"
import { Grid, Typography, Paper, Hidden } from '@material-ui/core'
import Categorias from './Categorias'
import API from '../../axios/api'
import { connect } from "react-redux"
import ComponenteNoticia from "./ComponentNoticia"
class NoticiaCategoria extends Component {
    constructor(props){
        super(props)
        this.state={
            noticia:{},
            cat:''
        }
    }
    componentDidMount(){
        ///console.log('didMount')
       
        this.cargar(this.props.match.params.cat)
    }
    componentDidUpdate(){
      /// console.log(this.state.cat,this.props.match.params.cat)
       if(this.state.cat !== this.props.match.params.cat){
           this.cargar(this.props.match.params.cat)
       }     
        return false;
    }
    cargar(ide){
        API.get('api/noticiacat/'+ide)
        .then(response => {
          ///mandar al estado
  
          //this.props.cargarNoticia(response.data)
         // console.log(response.data)
          this.setState({cat:this.props.match.params.cat})
          this.setState({noticia:response.data})
          
        })
        .catch(error => {
          console.log(error)
        })
        .finally(function () {
  
        }) 
    }
    
    render(){
        return(
            <>
            {this.state.noticia.length > 0 &&
           
           <Grid container>
               <Hidden mdUp ><Categorias categoria={this.props.match.params.cat}/></Hidden>
               <Grid item xs={12} md={9} className="noti-view">
                   <Paper className="noti-view noti-comp">

                   <Typography variant="h3" component="h2" style={{color:this.props.config.configuracion.color_a, fontWeight:500,fontStyle:"italic", paddingBottom:"1rem"}} align="center">{this.props.match.params.categoria}</Typography>
                  
                   <ComponenteNoticia noticia={this.state.noticia} color={this.props.config.configuracion.color_b} colora={this.props.config.configuracion.color_a}/>
                  
                   </Paper>
               </Grid>
               <Grid item xs={12} md={3}>
               <Hidden smDown>
                    <Categorias categoria={this.props.match.params.cat}/>  
                    </Hidden>
                  
               </Grid>
           </Grid>}
           
           </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      config: state.main.empresa
    }
  }



export default connect(mapStateToProps, null)(NoticiaCategoria)