import React from 'react'
import { Grid, Typography,IconButton } from '@material-ui/core'
import {
    FacebookIcon,
    WhatsappIcon,} from 'react-share';
import './layaut.css'
const Footer = (props)=>{
   // console.log(props)
    let nunm = props.empresa.fax;
    return(
        <Grid container className="footer">
           
            <Grid item className="ft-img">
                <img src={props.logo}/>
            </Grid>
            <Grid item xs={12} md={6} className="ft-info">
                <div className="ft-rss">
                <IconButton
                href={props.empresa.facebook}
                target="blank"
                >
                    <FacebookIcon
                    size={40}
                    borderRadius={10} />
                </IconButton>
            
                <IconButton
                href={props.empresa.whatssap}
                target="blank"
                >
                <WhatsappIcon
                    size={40}
                    borderRadius={10} />
                </IconButton>
                </div>

                <div className="ft-dts">
                <Typography variant={"body2"} component={"p"} color={"primary"}>Email: <a className="wnum" href={"mailto:"+props.empresa.email}> {props.empresa.email}</a>
                </Typography>
              <Typography variant={"body2"} component={"p"} color={"primary"}>Teléfono: <a className="wnum" href={"tel:"+props.empresa.telefonos}> (+591) {props.empresa.telefonos}</a> </Typography>
              <Typography variant={"body2"} component={"p"} color={"primary"}>Whatssap: <a className="wnum" href={"https://wa.me/591"+props.empresa.fax+"?text=MarcaLaPaz"}  target="blank"> (+591) {nunm}</a></Typography>
              <Typography variant={"body2"} component={"p"}>Derechos Reservados 2020 Marca La Paz</Typography>
                </div>
            </Grid>
        </Grid>
    )
}

export default Footer